<template>
  <div class="field margin-top-10" style="display: flex;flex-direction: column;margin-bottom:15px;">
    <div class="control" style="display:flex;align-items: center;margin-bottom: 15px;">
    <label class="label" style="margin-bottom: 0;display:block;width:120px;">Icon</label>
        <v-select
            style="z-index: 21;max-width: 150px;"
            outlined
            solo
            flat
            hide-details
            dense
            :menu-props="{ contentClass: 'my-awesome-list' }"
            :items="icons.data.map((item) => item.url)"
            :value="menuItem.icon[landing.current_lang]"
            v-model="menuItem.icon[landing.current_lang]"
            @click="showIcons = !showIcons"
    >
        <template v-slot:selection="{ item }">
            <img class="icon" :src="item" />
        </template>
        <template v-slot:item="{ item }">
            <img class="icon" :src="item" />
        </template>
      </v-select>
        <div v-if="showIcons" style="width: 15vw;height:150px;background:#fff;border:1px solid #ccc;position: absolute;top:74px;z-index: 100;display:flex;flex-wrap: wrap;overflow-y:scroll;overflow-x:hidden;">
            <div v-for="icon in icons.data.map((item) => item.url)" :key="icon"  style="height: 40px;width: 40px;padding:5px;" :style="`${menuItem.icon[landing.current_lang] === icon?'border:2px solid #007db5;background:#ddd;':'' };`" @click="handleChange(icon)">
                <img :src="icon" :alt="icon" style="height: 100%;width: 100%;cursor: pointer;">
            </div>
        </div>
    </div>
    <div class="control" style="display: flex;align-items:center;">
      <label class="label" style="margin-bottom:0;display: block;width:120px;">{{ $t('constructor["Название кнопки"]') }}</label>
      <v-text-field
          outlined
          solo
          ref="name"
          flat
          dense
          hide-details
          style="max-width:300px;"
          :value="menuItem.text[currentLang]"
          :rules="nameRules"
          @input="handleChangeName"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  props: {
    menuItem: Object,
  },
  computed: {
    ...mapState("landing", ["menu","icons"]),
    ...mapState(['landing']),
    ...mapState("menu", ["selectedMenuItem"]),
    nameRules() {
      return [
        (value) => {
          const isEmpty = !value;
          return isEmpty ? "Field is required" : true;
        },
        (value) => {
          const ifHasUrlSymbols = value.includes("/") || value.includes("?") || value.includes(".");
          return ifHasUrlSymbols ? "The field must not have slashes, dots, or question marks" : true;
        },
      ];
    },
    currentLang(){
      return this.$store.state.landing.current_lang;
    }
  },
    data(){
      return{
          showIcons:false
      }
    },
  methods: {
    ...mapMutations("landing", ["setIsValid"]),
    handleChange(value) {
      this.showIcons = false;
      if(!value){
        this.menuItem.icon[this.landing.current_lang] = "";
      }else{
        this.menuItem.icon[this.landing.current_lang] = value;
      }
      this.selectedMenuItem.icon[this.currentLang] = this.menuItem.icon[this.currentLang];
    },
    handleChangeName(value) {
      if(value.toLowerCase() === 'cart' || value.toLowerCase() === 'reviews' || value.toLowerCase() === 'favorite' || value.toLowerCase() === 'profile'){
        let val = value;
        this.menuItem.text[this.currentLang] = val;
      }else{
        if(this.currentLang === this.$store.state.landing.advanced.default_lang){
          let val = value;
          this.setIsValid(!(!val || value.includes("/") || value.includes("?") || value.includes(".")));
          this.menuItem.text[this.currentLang] = val;
          this.menuItem.page = val;
        }
        else{
          let val = value;
          this.menuItem.text[this.currentLang] = val;
        }
      }
      this.selectedMenuItem.text[this.currentLang] = this.menuItem.text[this.currentLang];
    },
  },
};
</script>

<style lang="scss">
.icon {
  width: 32px;
  height: 32px;
  margin: 5px 0;
}
.margin-top-20{
 margin-top: 20px;
}
    .my-awesome-list{display: none;}
</style>
